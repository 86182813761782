import { createAction, createReducer } from '@reduxjs/toolkit';

import store, { TDispatch } from '..';
import { IThreadListItem } from './interfaces';
import { TTOPIC } from './types/TTopic';
import { threadFetchBuilder } from './fetchers/threads';
import { topicFetchBuilder } from './fetchers/topics';
import { messageFetchBuilder } from './fetchers/messages';
import { attachmentFetchBuilder } from './fetchers/attachment';

const LOAD_TOPICS = 'neuro-ui/messaging/LOAD_TOPICS';
const LOAD_THREADLIST = 'neuro-ui/messaging/LOAD_THREADLIST';

const CREATE_TOPIC = 'neuro-ui/messaging/CREATE_TOPIC';
const CREATE_THREAD = 'neuro-ui/messaging/CREATE_THREAD';

const loadTopicsAction = createAction(LOAD_TOPICS, (topics: TTOPIC[]) => {
  return {
    payload: { topics },
  };
});
const loadThreadListAction = createAction(LOAD_THREADLIST, (threads: IThreadListItem[]) => {
  return {
    payload: { threads },
  };
});

const createTopicAction = createAction(CREATE_TOPIC, (topic: TTOPIC) => {
  return {
    payload: { topic },
  };
});
const createThreadAction = createAction(CREATE_THREAD, (thread: IThreadListItem) => {
  return {
    payload: { thread },
  };
});

const loadThreadList =
  (onlyPatient?: boolean) =>
  (dispatch: TDispatch): Promise<boolean> => {
    return threadFetchBuilder<'list'>({ name: onlyPatient ? 'listPatient' : 'listOrg' })
      .then((data) => {
        if (data) {
          Array.isArray(data) && dispatch(loadThreadListAction(data));
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };

const newMessageToThread = async (messageBody: string, patientId: string, threadId: string) => {
  const newMessage = await messageFetchBuilder<'create'>({
    name: 'create',
    queryParams: [patientId, threadId],
    body: messageBody,
  });

  const sentMessage =
    newMessage &&
    (await messageFetchBuilder<'send'>({
      name: 'send',
      queryParams: [patientId, newMessage.id],
    }));
  return sentMessage ? { ...newMessage } : false;
};

/**
 * Pipeline to create new topic -> thread -> message
 */
const createNewDiscussionWithPatient =
  (topicTitle: string, messageBody: string) =>
  async (dispatch: TDispatch): Promise<boolean> => {
    const userData = store.getState().session.data;
    const patientid = userData!.patientid;

    // Create new topic
    const newTopic = await topicFetchBuilder<'create'>({
      name: 'create',
      body: { title: topicTitle, targetPatients: [patientid] },
    });

    // Activate new topic
    const activatedTopic =
      newTopic &&
      (await topicFetchBuilder<'activate'>({
        name: 'activate',
        queryParams: [newTopic.id],
      }));

    // Create new thread
    const newThread =
      activatedTopic && (await threadFetchBuilder<'create'>({ name: 'create', queryParams: [patientid, newTopic.id] }));

    // Create and send new message
    const sentMessage = newThread && (await newMessageToThread(messageBody, patientid, newThread.id));

    // Assign the thread to the message sender
    sentMessage && (await threadFetchBuilder({ name: 'assign', queryParams: [newThread.id, sentMessage.senderId] }));

    return new Promise(() =>
      setTimeout(async () => {
        if (sentMessage) {
          return await loadThreadList(true)(dispatch).then((res) => res);
        }
        return false;
      }, 500),
    );
  };

const postNewAttachment =
  (
    attachment: File,
    patientId: string,
    updateOnlyPatientThreads: boolean,
    existingMessageData?: {
      topicId: string;
      threadId: string;
      messageId?: string;
    },
  ) =>
  async (dispatch: TDispatch): Promise<boolean> => {
    let topicId = existingMessageData?.topicId;
    let threadId = existingMessageData?.threadId;
    let messageId = existingMessageData?.messageId;

    if (!existingMessageData) {
      // Create new topic and thread
      topicId = '';
      threadId = '';
    }

    if (!topicId || !threadId) return false;

    if (!messageId) {
      // Create new message
      const newMessage = await messageFetchBuilder<'create'>({
        name: 'create',
        queryParams: [patientId, threadId],
        body: null,
      });
      messageId = newMessage ? newMessage?.id : undefined;
    }
    if (!messageId) return false;
    // Create new attachment for the message
    const newAttachment = await attachmentFetchBuilder<'create'>({
      name: 'create',
      queryParams: [patientId, topicId, threadId, messageId],
      body: attachment,
      contentType: attachment.type,
    });

    if (newAttachment) {
      // If the attachment creation was successfull, then send the message
      await messageFetchBuilder<'send'>({
        name: 'send',
        queryParams: [patientId, messageId],
      });
    } else {
      // Delete message if attachment creation failed
      // Maybe inform the user somehow
      await messageFetchBuilder<'delete'>({ name: 'delete', queryParams: [patientId, messageId] });
    }

    return new Promise(() =>
      setTimeout(async () => {
        if (newAttachment) {
          return await loadThreadList(updateOnlyPatientThreads)(dispatch).then((res) => res);
        }
        return false;
      }, 500),
    );
  };

export const actions = {
  loadThreadList,
  newMessageToThread,
  createNewDiscussionWithPatient,
  postNewAttachment,
};

const initialState: IMessagingStore = {};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadTopicsAction, (state, action) => {
      state.topics = action.payload.topics;
    })
    .addCase(loadThreadListAction, (state, action) => {
      state.threads = action.payload.threads;
    })
    .addCase(createTopicAction, (state, action) => {
      state.topics = [...(state.topics || []), action.payload.topic];
    })
    .addCase(createThreadAction, (state, action) => {
      state.threads = [...(state.threads || []), action.payload.thread];
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
